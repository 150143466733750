<template>
  <div>
    <modal
      ref="modal"
      :showing="showing"
      :show-close="showClose"
      :background-close="backgroundClose"
      :small="true"
      @close="close"
    >
      <div class="bg-grayVeryLight relative mx-auto lg:mx-0 p-24 lg:p-64 text-black">
        <div class="grid grid-cols-12 gap-24 text-center">
          <h1 class="h1 mb-32 col-span-12">
            Sprache/Language
          </h1>
          <div class="col-start-1 col-span-12 mb-16 text-gray text-left">
            <p v-if="[language, primaryLanguage, browserLanguage].includes('de')" class="mb-8 lg:mb-12">
              Die aktuelle Sprache der Webseite ist "{{ getLanguageName($root.$i18n.locale) }}". Möchten Sie in dieser Sprachversion bleiben oder zu einer anderen wechseln?
            </p>
            <p class="mb-8 lg:mb-12">
              The current language of the website is "{{ getLanguageName($root.$i18n.locale) }}". Would you like to remain on this language version or switch to another one?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('el')" class="mb-8 lg:mb-12">
              Η τρέχουσα γλώσσα του ιστότοπου είναι "{{ getLanguageName($root.$i18n.locale) }}". Θέλετε να παραμείνετε σε αυτήν την έκδοση γλώσσας ή να αλλάξετε σε άλλη;
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('es')" class="mb-8 lg:mb-12">
              El idioma actual del sitio web es "{{ getLanguageName($root.$i18n.locale) }}". ¿Desea permanecer en esta versión de idioma o cambiar a otra?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('fr')" class="mb-8 lg:mb-12">
              La langue actuelle du site web est "{{ getLanguageName($root.$i18n.locale) }}". Souhaitez-vous rester dans cette version linguistique ou en changer?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('it')" class="mb-8 lg:mb-12">
              La lingua attuale del sito web è "{{ getLanguageName($root.$i18n.locale) }}". Vorresti rimanere in questa versione linguistica o passare a un'altra?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('nl')" class="mb-8 lg:mb-12">
              De huidige taal van de website is "{{ getLanguageName($root.$i18n.locale) }}". Wilt u op deze taalversie blijven of naar een andere overschakelen?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('pl')" class="mb-8 lg:mb-12">
              Aktualny język strony to "{{ getLanguageName($root.$i18n.locale) }}". Czy chcesz pozostać w tej wersji językowej, czy przełączyć na inną?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('pt')" class="mb-8 lg:mb-12">
              A linguagem atual do site é "{{ getLanguageName($root.$i18n.locale) }}". Gostaria de permanecer nesta versão do idioma ou mudar para outra?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('ru')" class="mb-8 lg:mb-12">
              Текущий язык сайта - "{{ getLanguageName($root.$i18n.locale) }}". Хотите остаться на этой языковой версии или переключиться на другую?
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('ar')" class="mb-8 lg:mb-12 rtl text-right">
              اللغة الحالية للموقع هي "{{ getLanguageName($root.$i18n.locale) }}". هل ترغب في البقاء في هذا الإصدار اللغوي أم تغيير إلى آخر؟
            </p>
            <p v-if="[language, primaryLanguage, browserLanguage].includes('he')" class="mb-8 lg:mb-12 rtl text-right">
              השפה הנוכחית של האתר היא "{{ getLanguageName($root.$i18n.locale) }}". האם אתה רוצה להישאר בגרסה זו של השפה או לעבור לאחרת?
            </p>
          </div>
          <div class="col-start-1 col-span-12 mb-16">
            <select v-model="$root.$i18n.locale" type="select" class="language-switcher language-switcher--large col-span-1">
              <option v-if="isEmpty(languages) || languages.includes('de')" value="de">
                Deutsch
              </option>
              <option v-if="isEmpty(languages) || languages.includes('en')" value="en">
                English
              </option>
              <option v-if="isEmpty(languages) || languages.includes('es')" value="es">
                Español
              </option>
              <option v-if="isEmpty(languages) || languages.includes('fr')" value="fr">
                Français
              </option>
              <option v-if="isEmpty(languages) || languages.includes('it')" value="it">
                Italiano
              </option>
              <option v-if="isEmpty(languages) || languages.includes('nl')" value="nl">
                Nederlands
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pl')" value="pl">
                Polski
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pt')" value="pt">
                Português
              </option>
              <option v-if="isEmpty(languages) || languages.includes('ru')" value="ru">
                Русский язык
              </option>
              <option v-if="isEmpty(languages) || languages.includes('el')" value="el">
                Ελληνικά
              </option>
            </select>
          </div>
          <button-big class="col-start-1 col-span-12" @click="confirmAndClose">
            <span class="text-16 text-white font-medium">
              {{ $t('confirmLanguageSelection') }}
            </span>
          </button-big>
          <div class="text-gray mt-16 col-start-1 col-span-12 text-body-small hidden lg:block">
            <p class="mb-8">
              {{ $t('languageSwitchDesktop') }}
            </p>
            <p class="mb-8">
              The language selection can be changed at any time, even later, in the bottom left corner.
            </p>
          </div>
          <div class="text-gray mt-16 col-start-1 col-span-12 text-body-small lg:hidden">
            <p class="mb-8">
              {{ $t('languageSwitchMobile') }}
            </p>
            <p class="mb-8">
              The language selection can be changed at any time, even later, in the menu (click in the top right corner to open).
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';
import Modal from './Modal';
import ButtonBig from '../ButtonBig/ButtonBig';
import { isEmpty, isNil } from 'lodash/lang';

export default {
  name: 'LanguageModal',

  components: {
    XIcon,
    Modal,
    ButtonBig,
  },

  props: {
    // showing: {
    //   required: true,
    //   type: Boolean,
    // },
    showClose: {
      type: Boolean,
      default: true,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    languages: {
      type: Array,
      default: () => ['de', 'en'],
      required: false,
    },
  },

  data: function () {
    return {
      showing: false,
      browserLanguage: null,
    };
  },

  computed: {
    hotel() {
      return this.$store.getters.hotel;
    },
    primaryLanguage() {
      if (!isNil(this.hotel) && !isNil(this.hotel.details)) return this.hotel.details.primaryLanguage;
      else return null;
    },
    language() {
      if (isNil(this.$root)) return null;
      return this.$root.$i18n.locale;
    },
  },

  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body')
          .classList
          .add('overflow-hidden');
      }
      return document.querySelector('body')
        .classList
        .remove('overflow-hidden');
    },
  },

  created() {
    this.$serviceBus.$on('shortcut.esc', this.close);
    this.$serviceBus.$on('languageModal.show', () => { this.showing = true; });
    this.$serviceBus.$on('languageModal.hide', () => {
      this.showing = false;
      // this.close();
    });

    let browserLanguage = navigator.language || navigator.userLanguage;
    if (!isEmpty(browserLanguage)) {
      browserLanguage = browserLanguage.substring(0, 2);
    }

    this.browserLanguage = browserLanguage || null;
  },

  beforeDestroy() {
    this.$serviceBus.$off('shortcut.esc', this.close);
    this.$serviceBus.$off('languageModal.show');
    this.$serviceBus.$off('languageModal.hide');

    return document.querySelector('body')
      .classList
      .remove('overflow-hidden');
  },

  methods: {
    isEmpty,
    close() {
      this.$serviceBus.$emit('languageModal.hide');
      // this.$refs.modal.close();
    },
    getLanguageName(lang) {
      switch (lang) {
        case 'de': return 'Deutsch';
        case 'en': return 'English';
        case 'es': return 'Español';
        case 'fr': return 'Français';
        case 'it': return 'Italiano';
        case 'nl': return 'Nederlands';
        case 'pl': return 'Polski';
        case 'pt': return 'Português';
        case 'ru': return 'Русский язык';
        case 'el': return 'Ελληνικά';
        default: return lang;
      }
    },
    confirmAndClose() {
      this.$serviceBus.$emit('languageModal.confirm', this.$root.$i18n.locale);
      this.close();
    },
  },

  i18n: {
    messages: {
      de: {
        languageSwitchDesktop: 'Die Auswahl der Sprache kann jederzeit, auch später, in der linken unteren Ecke geändert werden',
        languageSwitchMobile: 'Die Auswahl der Sprache kann jederzeit, auch später, im Menü (zum Öffnen in die recht obere Ecke klicken) geändert werden.',
        confirmLanguageSelection: 'Sprachauswahl bestätigen',
      },
      ar: {
        languageSwitchDesktop: 'يمكن تغيير اختيار اللغة في أي وقت، حتى لاحقًا، في الزاوية السفلية اليسرى',
        languageSwitchMobile: 'يمكن تغيير اختيار اللغة في أي وقت، حتى لاحقًا، في القائمة (انقر في الزاوية اليمنى العليا لفتحها).',
        confirmLanguageSelection: 'تأكيد اختيار اللغة',
      },
      el: {
        languageSwitchDesktop: 'Η επιλογή γλώσσας μπορεί να αλλάξει ανά πάσα στιγμή, ακόμη και αργότερα, στην κάτω αριστερή γωνία',
        languageSwitchMobile: 'Η επιλογή γλώσσας μπορεί να αλλάξει ανά πάσα στιγμή, ακόμη και αργότερα, από το μενού (κάντε κλικ στην επάνω δεξιά γωνία για να το ανοίξετε).',
        confirmLanguageSelection: 'Επιβεβαίωση επιλογής γλώσσας',
      },
      es: {
        languageSwitchDesktop: 'La selección del idioma se puede cambiar en cualquier momento, incluso más tarde, en la esquina inferior izquierda',
        languageSwitchMobile: 'La selección del idioma se puede cambiar en cualquier momento, incluso más tarde, en el menú (haga clic en la esquina superior derecha para abrirlo).',
        confirmLanguageSelection: 'Confirmar selección de idioma',
      },
      fr: {
        languageSwitchDesktop: 'La sélection de la langue peut être modifiée à tout moment, même plus tard, dans le coin inférieur gauche',
        languageSwitchMobile: 'La sélection de la langue peut être modifiée à tout moment, même plus tard, dans le menu (cliquez dans le coin supérieur droit pour l\'ouvrir).',
        confirmLanguageSelection: 'Confirmer la sélection de la langue',
      },
      he: {
        languageSwitchDesktop: 'ניתן לשנות את בחירת השפה בכל עת, גם מאוחר יותר, בפינה השמאלית התחתונה',
        languageSwitchMobile: 'ניתן לשנות את בחירת השפה בכל עת, גם מאוחר יותר, בתפריט (לחץ בפינה הימנית העליונה כדי לפתוח).',
        confirmLanguageSelection: 'אשר את בחירת השפה',
      },
      it: {
        languageSwitchDesktop: 'La selezione della lingua può essere modificata in qualsiasi momento, anche successivamente, nell\'angolo in basso a sinistra',
        languageSwitchMobile: 'La selezione della lingua può essere modificata in qualsiasi momento, anche successivamente, nel menu (fai clic nell\'angolo in alto a destra per aprirlo).',
        confirmLanguageSelection: 'Conferma selezione della lingua',
      },
      nl: {
        languageSwitchDesktop: 'De taalkeuze kan op elk moment worden gewijzigd, ook later, in de linkerbenedenhoek',
        languageSwitchMobile: 'De taalkeuze kan op elk moment worden gewijzigd, ook later, in het menu (klik in de rechterbovenhoek om het te openen).',
        confirmLanguageSelection: 'Bevestig taalkeuze',
      },
      pl: {
        languageSwitchDesktop: 'Wybór języka można zmienić w dowolnym momencie, nawet później, w lewym dolnym rogu',
        languageSwitchMobile: 'Wybór języka można zmienić w dowolnym momencie, nawet później, w menu (kliknij w prawym górnym rogu, aby otworzyć).',
        confirmLanguageSelection: 'Potwierdź wybór języka',
      },
      pt: {
        languageSwitchDesktop: 'A seleção do idioma pode ser alterada a qualquer momento, mesmo mais tarde, no canto inferior esquerdo',
        languageSwitchMobile: 'A seleção do idioma pode ser alterada a qualquer momento, mesmo mais tarde, no menu (clique no canto superior direito para abri-lo).',
        confirmLanguageSelection: 'Confirmar seleção de idioma',
      },
      ru: {
        languageSwitchDesktop: 'Выбор языка можно изменить в любое время, даже позже, в нижнем левом углу',
        languageSwitchMobile: 'Выбор языка можно изменить в любое время, даже позже, в меню (щелкните в правом верхнем углу, чтобы открыть его).',
        confirmLanguageSelection: 'Подтвердить выбор языка',
      },
    },
  },

};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
